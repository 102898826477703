.Transition {
  &-enter {
    opacity: 0;
    background: black;
  }
  &-enter-active {
    opacity: 1;
    transition: opacity 400ms;
    background: none;
  }
  &-exit {
    opacity: 1;
  }
  &-exit-active {
    opacity: 0;
    transition: opacity 400ms;
  }
}
