@import 'variables';
@import "bootstrap";
@import 'icomoon';
@import "fonts";
@import "drupal";
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700|Source+Sans+Pro:300,400,600,700&display=swap');

body, html {
  margin: 0;
  font: normal #{$font-size}/#{$line-height} $font-family;
  color: $color-tuna;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
}

/* apply a natural box layout model to all elements, but allowing components to change */
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

a {
  color: $color-edeavour;

  &:focus, &:active {
    @include outline-styling($color-secondary)
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

h1, h2, h3, h4, h5 {
  color: $color-primary;
  font-weight: 400;
  line-height: 1.4em;
}

p {
  font-size: 1rem;
  line-height: 1.5em;
}

img {
  max-width: 100%;
}

.EmbeddableContainer {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  margin: $grid-gutter-width 0;

  & iframe, & object, & embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.RouterContainer {
  min-height: 50vh;
  overflow-x: hidden;
  background-color: $color-iron;
}

@media (max-width: $grid-breakpoint-md-below) {
  h1, h2, h3, h4, h5 {
    font-size: 1.2em;
    line-height: 1.2em;
  }
}
