$grid-columns: 12 !default;
$grid-gutter-width: 20px !default;
$grid-gutter-width-half: ($grid-gutter-width / 2);

$grid-breakpoint-xs: 0;
$grid-breakpoint-xs-below: $grid-breakpoint-xs - 1;
$grid-breakpoint-sm: 576px;
$grid-breakpoint-sm-below: $grid-breakpoint-sm - 1;
$grid-breakpoint-md: 768px;
$grid-breakpoint-md-below: $grid-breakpoint-md - 1;
$grid-breakpoint-lg: 1024px;
$grid-breakpoint-lg-below: $grid-breakpoint-lg - 1;
$grid-breakpoint-xl: 1180px;
$grid-breakpoint-xl-below: $grid-breakpoint-xl - 1;

$grid-breakpoints: (
  xs: $grid-breakpoint-xs,
  sm: $grid-breakpoint-sm,
  md: $grid-breakpoint-md,
  lg: $grid-breakpoint-lg,
  xl: $grid-breakpoint-xl,
);

$font-size: 14px;
$font-family: 'Open Sans';
$font-family-2nd: 'Source Sans Pro';
$line-height: 20px;

$color-content-bg: #ffffff;
$color-alabaster: #f8f8f8;
$color-alto: #d8d8d8;
$color-aluminium: #a8acb5;
$color-dusty-gray: #9b9b9b;
$color-gray-form-text: #8a8ba1;
$color-gray: #828282;
$color-boulder: #777777;
$color-dove-gray: #716b6b;
$color-thundora: #4a4a4a;
$color-monza: #d0021b;
$color-ramona: #ed1c24;
$color-edeavour: #005399;
$color-goeruy: #005dab;
$color-media-bg: #000000;

$color-iron: #f0f2f5;
$color-tuna: #4e4f5e;
$color-askor: #e1e3eb;

$color-primary: $color-edeavour;
$color-secondary: $color-ramona;
$color-white: $color-content-bg;
$color-black: $color-media-bg;

:export {
  color-primary: $color-primary;
  color-secondary: $color-secondary;
  color-iron: $color-iron;
  color-tuna: $color-tuna;
  color-white: $color-white;
  color-form-text: $color-gray-form-text;

  xs: $grid-breakpoint-xs;
  sm: $grid-breakpoint-sm;
  md: $grid-breakpoint-md;
  lg: $grid-breakpoint-lg;
  xl: $grid-breakpoint-xl;
}

@import 'mixins';
