@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?abugjs');
  src:  url('fonts/icomoon.eot?abugjs#iefix') format('embedded-opentype'),
  url('fonts/icomoon.woff2?abugjs') format('woff2'),
  url('fonts/icomoon.ttf?abugjs') format('truetype'),
  url('fonts/icomoon.woff?abugjs') format('woff'),
  url('fonts/icomoon.svg?abugjs#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="awsicon-"], [class*=" awsicon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.awsicon-instagram:before {
  content: "\e900";
}
.awsicon-tag:before {
  content: "\61";
}
.awsicon-people:before {
  content: "\62";
}
.awsicon-offer:before {
  content: "\63";
}
.awsicon-home:before {
  content: "\64";
}
.awsicon-search:before {
  content: "\66";
}
.awsicon-close:before {
  content: "\67";
}
.awsicon-info:before {
  content: "\6a";
}
.awsicon-twitter:before {
  content: "\65";
}
.awsicon-mail:before {
  content: "\68";
}
.awsicon-facebook:before {
  content: "\69";
}
.awsicon-icn-menu:before {
  content: "\6d";
}
.awsicon-calendar:before {
  content: "\6e";
}
.awsicon-instagram-svgrepo-com3:before {
  content: "\6b";
}
.awsicon-youtube:before {
  content: "\6c";
}
