@mixin outline-styling($color, $width: 2px, $style: dotted) {
  outline-color: darken($color, 15%);
  outline-style: $style;
  outline-width: $width/2;
  outline-offset: $width/2;
  //outline: none;
  //box-shadow: 0 0 ($width*2) $width darken($color, 25%);
}

@mixin box-shadow($shadows...) {
  box-shadow: $shadows;
}

// @link http://hackingui.com/front-end/a-pure-css-solution-for-multiline-text-truncation/
@mixin multiLine-ellipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white) {
  overflow: hidden;
  position: relative;
  line-height: $lineHeight;
  max-height: $lineHeight * $lineCount;
  text-align: justify;
  padding-right: 1em;
  &:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: $bgColor;
  }
}
