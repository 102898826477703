p.text-align {
  &-left {
    text-align: left;
  }
  &-right {
    text-align: right;
  }
  &-justify {
    text-align: justify;
  }
  &-center {
    text-align: center;
  }
}
