@import "../../../assets/variables";

.Nav {
  font-size: 1rem;
  list-style: none;
  margin: 0;
  padding: 0;

  &-item {
    line-height: 2em;
    font-size: 1em;

    & * {
      text-decoration: none;
    }

    a, button {
      font-size: 1em;
      cursor: pointer;
      padding: .3em .5em;
      margin: 0 -.5em;
      border-radius: 16px;
      border: 0;
      position: relative;
      color: $color-secondary;
      background: transparent;

      &:hover, &:active, &:focus {
        color: $color-white;
        background: $color-secondary;
      }

      &:active, &:focus {
        z-index: 1000;
        @include outline-styling($color-secondary);
      }

      &:visited ~ &:hover {
        color: $color-secondary;
      }
    }
  }
}
