@import "../../assets/variables";

.Navbar {
  font-size: 1rem;
  display: flex;
  padding: $grid-gutter-width $grid-gutter-width-half;
  flex: 1 1 calc(100% - #{$grid-gutter-width});
  justify-content: space-between;
  align-content: center;
  align-items: center;

  &-menu {
    order: 2;
  }

  &-logo {
    order: 1;
  }

  &-hamburger {
    order: 3;
  }
}

.Navbar-logo {
  flex: 0 0 auto;
}

.Navbar-shares {
  .Icon {
    font-size: 2rem !important;
    position: relative !important;
  }
}

.Navbar-menu {
  font-size: 1.25rem;
  flex: 1 1 auto;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;

  &-item {
    flex: 0 1 auto;
    list-style: none;
    align-self: center;
    position: relative;
    margin: 0 $grid-gutter-width;
  }

  a {
    color: $color-secondary;
    text-decoration: none;
    padding: .5em .5em .5em 1.5em;
  }

  .Icon {
    margin: 0 .5em;
    color: $color-primary;
    font-size: 1.125em;
    position: absolute;
    display: block;
    left: -.75em;
    top: 0;
  }


  &--shares {
    flex: 0 0 auto;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    .Icon {
      position: relative;
      font-size: 1.5em;
      margin: 0 0 0 $grid-gutter-width-half;
      left: 0;
    }
  }

  &--shares &-item {
    width: auto;
    margin: 0;
    a {
      padding: 0;
    }
  }

}

.Navbar-hamburger {
  background: none;
  border: 0;
  vertical-align: top;
  line-height: 2em;
  cursor: pointer;
  font-size: 1.25rem;

  &:focus, &:active, &:hover {
    border: 0;
    background: none;
  }

  .hamburger-box {
    vertical-align: middle;
  }
}

@media (max-width: map-get($grid-breakpoints, "md")) {
  .Navbar {
    flex-wrap: wrap;
    padding: $grid-gutter-width-half;
    background: $color-white;

    &-menu {
      flex-wrap: wrap;
      order: 3;
      flex: 1 1 100%;
      justify-content: space-between;
      margin: $grid-gutter-width 0 0 0;

      &-item {
        flex: 1 1 100%;
        margin: $grid-gutter-width-half 0 0 0;
        padding: 0;

        a {
          display: block;
        }
      }

      .Icon {
        top: 0.3em;
      }
    }

    &-menu--shares {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;
    }

    &-menu--shares &-menu-item {
      flex: 0 1 auto;

      .Icon {
        top: auto;
        left: auto;
      }
    }
  }
}


@media (max-width: $grid-breakpoint-lg-below) {
  .Navbar {
    &-menu {
      &-item {
        font-size: 0.75em;
        margin: 0 $grid-gutter-width-half;
      }
    }
  }
}
